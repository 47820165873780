import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import store from './redux/store'
import Login from './pages/Login';
import Homepage from './pages/Homepage';
import Email from './components/Email';
import TermsOfService from './components/TermsOfService';
import TechnicalSupport from './components/TechnicalSupport';
import PrivacyPolicy from './components/PrivacyPolicy';
import Faq from './components/Faq';
import Contact from './components/Contact';
import Workspace from './components/Workspace';
import Chatbot from './components/Chatbot';
import TextToSql from './components/TextToSql';
import MultiModality from './components/MultiModality';
import CallCenter from './components/CallCenter';

function App() {
  return (
    <Provider store={store}>
    <div className='h-[100vh]'>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path='/contact-us' element={<Contact/>} />
        <Route path="/technical-support" element={<TechnicalSupport/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/faq" element={<Faq/>} />
          <Route path="/tos" element={<TermsOfService/>}/>
        {/* Parent route for Homepage with nested routes */}
        <Route path="/" element={<Homepage />}>
          {/* Nested routes */}
          <Route path="email" element={<Email />} />
          <Route path="chatbot" element={<Chatbot />} />
          <Route path="texttosql" element={<TextToSql />} />
          <Route path="multimodality" element={<MultiModality />} />
          <Route path="call-center" element={<CallCenter />} />
          <Route path="workspace" element={<Workspace />} />
          
        </Route>        
      </Routes>
      </div>
      </Provider>
  );
}

export default App;