import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: !!localStorage.getItem('isLoggedIn'),
    username: "",
};

const authSlice = createSlice ({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            localStorage.setItem('isLoggedIn', 'true'); // storing in localStorage
            state.username = action.payload;  // set the username from the payload
        },
        logout: (state) => {
            state.isLoggedIn = false;
            localStorage.removeItem('isLoggedIn'); //clear from localStorage
            state.username = "";
        },
    },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;