import React, { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useSelector } from 'react-redux';
import img from "../images/bg6.jpg";
import Pricing from '../components/Pricing';
import Testimonial from '../components/Testimonial';

const Homepage = () => {
    const location = useLocation();
   
    const { isLoggedIn, username } = useSelector((state) => state.auth);

    useEffect(() => {
        const handleNextClick = () => {
          let lists = document.querySelectorAll(".item");
          document.getElementById("slide").appendChild(lists[0]);
        };
    
        const handlePrevClick = () => {
          let lists = document.querySelectorAll(".item");
          document.getElementById("slide").prepend(lists[lists.length - 1]);
        };
    
        const nextButton = document.getElementById("next");
        const prevButton = document.getElementById("prev");
    
        if (nextButton && prevButton) {
          nextButton.addEventListener("click", handleNextClick);
          prevButton.addEventListener("click", handlePrevClick);
        }
    
        return () => {
          if (nextButton && prevButton) {
            nextButton.removeEventListener("click", handleNextClick);
            prevButton.removeEventListener("click", handlePrevClick);
          }
        };
      }, []);

      document.addEventListener('DOMContentLoaded', function() {
        const gdprBanner = document.getElementById('gdpr');
        const acceptButton = document.getElementById('acceptCookies');
      
        // Check if the user has already accepted cookies
        if (localStorage.getItem('cookiesAccepted')) {
          gdprBanner.style.display = 'none';
        }
      
        // Handle "Accept" button click
        acceptButton.addEventListener('click', function(event) {
          event.stopPropagation();  // Prevent this click from propagating to the document
          localStorage.setItem('cookiesAccepted', 'true');
          gdprBanner.style.display = 'none';  // Hide the banner after accepting
        });
      
        // Handle clicks anywhere on the document
        document.addEventListener('click', function(event) {
          // Check if the click is outside the banner (and the banner is visible)
          if (gdprBanner.style.display !== 'none' && !gdprBanner.contains(event.target)) {
            gdprBanner.style.display = 'none';  // Hide the banner on outside click
          }
        });
      
        // Prevent the banner itself from closing if clicked
        gdprBanner.addEventListener('click', function(event) {
          event.stopPropagation();  // Stop the event from reaching the document
        });
      });
      
      

  return (
    <div className='w-screen' style={
        isLoggedIn
          ? { backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center' }
          : {}
      }>
        <div class="fixed bottom-5 left-1/2 z-50 -translate-x-1/2 rounded-full bg-white dark:bg-gray-800 p-2 drop-shadow-2xl max-sm:w-11/12" id="gdpr">
          <div class="flex items-center justify-between gap-6 text-sm">
            <div class="content-left pl-4 dark:text-white">
              This website uses cookies to improve your web experience.
            </div>
            <div class="content-right text-end">
              <button id="acceptCookies" class="cursor-pointer rounded-full bg-black dark:bg-gray-600 px-4 py-2 text-white">Accept</button>
            </div>
          </div>
        </div>
      <Navbar />
      <Outlet />
      {location.pathname === '/' && (
        <>
          {!isLoggedIn ? (
             
            <>
              <div className="flex my-auto max-w-screen items-center justify-evenly transform translate-x-[0.002%] translate-y-[-40%] transition-all h-[90vh] mt-72 -mb-72 ">
             <div id="slide">
                 <div className="item bg-indigo-50">
                 <div className="content cursor-pointer">
                     <div className="name">Email Automation</div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
         
                     <div className="des text-[#000000] text-sm">
                     AI agents can autonomously draft, schedule, and send emails based on customer interactions, behavior, and preferences. Whether it's responding to inquiries or sending marketing campaigns, email automation ensures personalized, timely communication with minimal human intervention.
                     </div>
                 </div>
                 </div>
                 <div className="item bg-yellow-50">
                 <div className="content cursor-pointer">
                     <div className="name">Chatbot Automation</div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our intelligent chatbots use AI agents to engage with customers in real-time, resolving queries, providing product recommendations, and enhancing customer support experiences. These AI-driven conversations feel natural and can handle complex scenarios, reducing the need for manual customer service efforts.
                     </div>
                 </div>
                 </div>
                 <div className="item bg-pink-50">
                 <div className="content cursor-pointer">
                     <div className="name">
                         Text to SQL Automation
                     </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our platform's AI workflows allow users to input natural language queries, which are then converted into SQL queries by intelligent agents. This allows for non-technical users to interact with databases and retrieve complex data insights without knowing SQL.

                     </div>
                 </div>
                 </div>
                 <div className="item bg-indigo-50">
                 <div className="content cursor-pointer">
                     <div className="name">
                         Call Center Automation
                     </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     AI agents can analyze and respond to customer inquiries through voice or text in call centers, providing immediate resolutions or escalating complex cases when necessary. This reduces wait times, improves customer satisfaction, and streamlines call center operations.
                     </div>
                 </div>
                 </div>
                 <div className="item bg-yellow-50">
                 <div className="content cursor-pointer">
                     <div className="name">
                        Image Processing and Automation                          
                      </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our AI workflows can process images for tasks such as object detection, facial recognition, or even automatic tagging and categorization. This is ideal for e-commerce, media, or security industries where quick image analysis and action are critical.
                     </div>
                 </div>
                 </div>
                 <div className="item bg-yellow-50">
                 <div className="content cursor-pointer">
                     <div className="name">
                     Text-to-Video Automation
                      </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our platform's AI agents convert textual content into engaging videos, automating the video creation process. Whether it's for marketing, training, or customer onboarding, the platform intelligently maps text inputs into visually rich video formats, saving time and effort in content creation.
                     </div>
                 </div>
                 </div>
             </div>
         
             <div className="buttons w-[87%]  bottom-[6%] -mt-7 absolute z-10 flex flex-row space-x-2  ">
                 <button id="prev">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                     <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                 </svg>
                 </button>
                 <button id="next">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                 </svg>
                 </button>
             </div>
              </div>
              <div className='flex flex-col'>
                <div class="mt-16 w-[80%] text-gray-600 body-font px-5 py-24 mx-auto text-center">
                  <h2 class="mx-auto sm:text-5xl text-8xl text-gray-900 font-medium title-font mb-2">Focusing on quality, <span className='text-gray-500'>we maintain customer trust </span></h2>
                </div>
                <ul className='mx-auto md:flex md:flex-row sm:flex-col'>
                  <li className='bg-gray-100 h-56 w-56 rounded-full border border-gray-200 cursor-pointer hover:bg-gray-50 hover:shadow-lg flex items-center justify-center font-semibold transition-all duration-200'>Client Name</li>
                  <li className='bg-gray-100 h-56 w-56 rounded-full border border-gray-200 cursor-pointer hover:bg-gray-50 hover:shadow-lg flex items-center justify-center font-semibold transition-all duration-200'>Client Name</li>
                  <li className='bg-gray-100 h-56 w-56 rounded-full border border-gray-200 cursor-pointer hover:bg-gray-50 hover:shadow-lg flex items-center justify-center font-semibold transition-all duration-200'>Client Name</li>
                  <li className='bg-gray-100 h-56 w-56 rounded-full border border-gray-200 cursor-pointer hover:bg-gray-50 hover:shadow-lg flex items-center justify-center font-semibold transition-all duration-200'>Client Name</li>
                  <li className='bg-gray-100 h-56 w-56 rounded-full border border-gray-200 cursor-pointer hover:bg-gray-50 hover:shadow-lg flex items-center justify-center font-semibold transition-all duration-200'>Client Name</li>
                </ul>
              </div>
              <Testimonial/>
              <Pricing />
            </>
          ) : (
            <div className='px-8 mb-12 ml-4 mr-4'>
              <p className='font-semibold mt-9 text-2xl pt-16 pb-5 mb-6'>Welcome {username}</p>
              <div className='md:grid md:grid-cols-3 gap-x-8 gap-y-8 sm:grid sm:grid-cols-2 xs:grid xs:grid-cols-1'>
                <Link to='/email' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Email Automation</p>
                  <p className='text-sm mb-4'>
                  AI agents can autonomously draft, schedule, and send emails based on customer interactions, behavior, and preferences. Whether it's responding to inquiries or sending marketing campaigns, email automation ensures personalized, timely communication with minimal human intervention.
                  </p>
                </Link>
                <Link to='/chatbot' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Chatbot Automation</p>
                  <p className='text-sm mb-4'>
                  Our intelligent chatbots use AI agents to engage with customers in real-time, resolving queries, providing product recommendations, and enhancing customer support experiences. These AI-driven conversations feel natural and can handle complex scenarios, reducing the need for manual customer service efforts.
                  </p>
                </Link>
                <Link to='/texttosql' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Text to SQL Automation</p>
                  <p className='text-sm mb-4'>
                  Our platform's AI workflows allow users to input natural language queries, which are then converted into SQL queries by intelligent agents. This allows for non-technical users to interact with databases and retrieve complex data insights without knowing SQL.
                  </p>
                </Link>
                <Link to='/multimodality' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Text-to-Video Automation</p>
                  <p className='text-sm mb-4'>
                  Our platform's AI agents convert textual content into engaging videos, automating the video creation process. Whether it's for marketing, training, or customer onboarding, the platform intelligently maps text inputs into visually rich video formats, saving time and effort in content creation.
                  </p>
                </Link>
                <Link to='/call-center' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Call Center Automation</p>
                  <p className='text-sm mb-4'>
                  AI agents can analyze and respond to customer inquiries through voice or text in call centers, providing immediate resolutions or escalating complex cases when necessary. This reduces wait times, improves customer satisfaction, and streamlines call center operations.
                  </p>
                </Link>
                <Link to='#' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Image Processing and Automation</p>
                  <p className='text-sm mb-4'>
                  Our AI workflows can process images for tasks such as object detection, facial recognition, or even automatic tagging and categorization. This is ideal for e-commerce, media, or security industries where quick image analysis and action are critical.
                  </p>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};
    

export default Homepage