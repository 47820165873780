import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Chatbot = () => {
    const nav = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); //getting login state from redux
    useEffect(() => {
      if(!isLoggedIn) {
          nav('/login');
      }
  }, [isLoggedIn, nav]);

  const [msg, setMsg] = useState('');

  const handleBack = () => {
    window.history.back();
  }

  // sendMessage function
async function sendMessage() {
    const messageInput = document.getElementById("topic");
  const story = messageInput.value;
  const emailId = 'ayushi.nayan@astradigit.com'; // You can dynamically set this if needed

  // Validate input fields
  if (story.trim().length === 0 || emailId.trim().length === 0) {
      alert('Please fill in all fields.');
      return;
  }

  // Display the user's message in the chat window
  const messagesContainer = document.getElementById('messages');
  const userMessage = document.createElement('div');
  userMessage.classList.add('message', 'user', 'p-2', 'w-fit', 'border', 'shadow-lg', 'rounded-lg');
  userMessage.innerHTML = `<span class="message-content">${sanitizeHTML(story)}</span>`;
  messagesContainer.appendChild(userMessage);

  // Clear the input field
  messageInput.value = '';

  // Optionally, scroll to the bottom of the chat window
  const chatWindow = document.getElementById('chat-window');
  chatWindow.scrollTop = chatWindow.scrollHeight;

  // Display a "Typing..." animation as the AI's temporary response
  const typingIndicator = document.createElement('div');
  typingIndicator.classList.add('message', 'ai', 'p-2', 'w-fit', 'border', 'shadow-lg', 'rounded-lg');
  typingIndicator.innerHTML = `
      <div class="typing-indicator">
          <span></span><span></span><span></span>
      </div>`;
  messagesContainer.appendChild(typingIndicator);
  chatWindow.scrollTop = chatWindow.scrollHeight;

  // Fetch the AI response
  try {
      const response = await fetch("/api/story", {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({"topic": story, "user_id": emailId})
      });

      if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! Status: ${response.status}\nMessage: ${errorText}`);
      }

      // Read the streamed response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let receivedText = '';

      while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          receivedText += decoder.decode(value);
      }

      // Remove the typing indicator after the response is fully received
      messagesContainer.removeChild(typingIndicator);

      // Display the AI's actual message
      const aiMessage = document.createElement('div');
      aiMessage.classList.add('message', 'ai', 'p-2', 'w-fit', 'border', 'shadow-lg', 'rounded-lg');
      aiMessage.innerHTML = `<span class="message-content">${sanitizeHTML(receivedText)}</span>`;
      messagesContainer.appendChild(aiMessage);
      chatWindow.scrollTop = chatWindow.scrollHeight;

  } catch (error) {
      // Remove the typing indicator even if an error occurs
      messagesContainer.removeChild(typingIndicator);

      // Handle errors by updating the AI message with an error message
      const aiMessage = document.createElement('div');
      aiMessage.classList.add('message', 'ai', 'p-2', 'w-fit', 'border', 'shadow-lg', 'rounded-lg');
      aiMessage.innerHTML = `<span class="message-content">Sorry, an error occurred. Please try again later.</span>`;
      messagesContainer.appendChild(aiMessage);
      console.error('Detailed error:', error);
  }
}

// Attach the click event listener to the send button
const sendBtn = document.getElementById('send-message');
if (sendBtn) {
  sendBtn.addEventListener('click', sendMessage);
}

// Handle the "Enter" key press for sending messages
const msgInput = document.getElementById('topic');
if (msgInput) {
  msgInput.addEventListener('keydown', function (e) {
    if (e.code === 'Enter') {
      e.preventDefault(); // Prevent default form submission behavior
      sendMessage();
    }
  });
}
// Utility function to sanitize HTML to prevent XSS attacks
function sanitizeHTML(str) {
  const temp = document.createElement('div');
  temp.textContent = str;
  return temp.innerHTML;
}

  return (
    <div className='pt-20'>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
        </div> 
        <div className='flex flex-col mx-auto bg-white shadow-md sm:w-[90%] md:w-[70%] rounded-md border p-4 gap-y-3 text-center'>
          <p className='text-center font-bold text-3xl'>
            Virtual Assistant
          </p>
          <p className='text-center text-gray-500 text-sm'>Your personal guide to smarter solutions and seamless assistance!</p>
          <div className='bg-gray-300 w-[100%] h-[1px]'></div>
          <div className='h-[400px]' id='chat-window'>
              <div id="messages" className=''>
                  <div className="message ai border p-2 w-fit shadow-lg rounded-lg">
                      <span className="message-content float-left">Welcome! How can I assist you today?</span>
                  </div>
              </div>
            </div>
            <div className='float-left w-full flex justify-between'>
              <div className='float-start justify-center items-center w-[100%]'>
                <input
                  type='text'
                  id='topic'
                  name='topic'
                  value={msg}
                  onChange={(e)=>setMsg(e.target.value)}
                  className='border border-gray-600 rounded-md p-2 float-left w-full'
                  placeholder='Enter your message...'
                  />
              </div>
              <button id='send-message'>
                <i className="ri-send-plane-2-line ml-2 text-4xl text-gray-600 hover:text-black"></i>
              </button>
            </div>
         
        
        </div>
    </div>
  )
}

export default Chatbot