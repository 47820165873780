import React, { useState } from 'react';
import { Box, Button, Container, FormControl, Input, InputGroup, InputRightElement, TabPanel, TabPanels, Tabs, useToast, VStack } from "@chakra-ui/react";
import { useDispatch } from 'react-redux';
import { login } from '../redux/authSlice';
import { useNavigate } from "react-router-dom";
import img from '../images/bg-supp.jpg';

const Login = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
  
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
  
    const [loading, setLoading] = useState(false);
    const toast = useToast();
  
    const submitHandler = async () => {
      setLoading(true);
      if (!username || !password) {
        toast({
          title: "Please fill all the fields",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        setLoading(false);
        return;
      }

      if (username === 'astradigit' && password === '9YsEC781qWfSm8') {
        setLoading(false);
        dispatch(login(username));
        nav('/');
        return;
      }      

       else{
            toast({
              title: "Error Occurred!",
              description: "Invalid Username/Password",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "bottom",
            });
            setLoading(false);
          }
        }

  return (
    <div className=' flex flex-col justify-between'>
    <div className='justify-center items-center'>
        <div className='flex'>
            <div className="md:w-[80%]">
                <div className='sm:hidden md:block' style={{ 
                    backgroundImage: `url(${img})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}></div>
            </div>
            <Container maxW="lg" className='px-8 flex' centerContent>
            <Box
                d="flex"
                justifyContent="center"
                p={1}
                bg="white"
                w="78%"
                m="40px 0 15px 0"
                borderRadius="lg"
            >
                <div className="flex">
                <p className="text-md p-3">
                    <span className='text-gray-700 text-md'>Login to</span><br></br>
                    <span className='text-green-900 font-semibold text-2xl'>Deploybydesign</span>
                </p>
                </div>
            </Box>
            <div className="container bg-white rounded-md mx-auto text-sm p-2">
                <Tabs variant="soft-rounded" className='mx-auto' w="84%" align='center'>
                <TabPanels>
                    <TabPanel>
                        <VStack className="text-sm">
                            <FormControl id="username" isRequired>
                                <Input
                                value={username}
                                type="username"
                                placeholder="Username"
                                onChange={(e) => setUsername(e.target.value)}
                                size="sm"
                                />
                            </FormControl>
                            <FormControl id="password" size="small" isRequired>
                                <InputGroup>
                                <Input
                                    value={password}
                                    type={show ? "text" : "password"}
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    size="sm"
                                />
                                <InputRightElement width="2.5rem" height="1.9rem" className="mr-[-2] cursor-pointer">
                                    <div  onClick={handleClick}>
                                    {show ? <i className="ri-eye-off-fill"></i> : <i className="ri-eye-fill"></i>}
                                    </div>
                                </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Button
                                colorScheme="blue"
                                width="100%"
                                style={{ marginTop: 15 }}
                                onClick={submitHandler}
                                isLoading={loading}
                                className='mb-6 text-sm'
                                size="sm"
                            >
                                Login
                            </Button>
                        </VStack>
                    </TabPanel>
                </TabPanels>

                {/* <div className='flex justify-center items-center'>
                    <div className='w-1/3 h-[1px] bg-gray-500 mr-2'></div>
                    <p className='text-center text-gray-500 text-sm'> Login with </p>
                    <div className='w-1/3 h-[1px] bg-gray-500 ml-2'></div>
                </div> */}
                </Tabs> 
            </div>
            </Container>
            
        </div>
    </div>
    </div>
  )
}

export default Login