import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Chatbot from '../components/Chatbot';
import Email from '../components/Email';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Workspace = () => {
    const nav = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); //getting login state from redux

    useEffect(() => {
        if(!isLoggedIn) {
            nav('/login');
        }
    }, [isLoggedIn, nav]);

    const [query, setQuery] = useState("");
    const handleChange = (e) => {
        setQuery(e.target.value);
      };
  return (
    <div className='p-6 pt-20'>
        <div className='text-md flex justify-between p-2 rounded-md bg-white mb-3 w-[35%] border'>
            <input type="text" 
              placeholder="Filter resources..." 
              className="rounded-md text-sm px-2 text-black p-1 w-full"
              required />
            <i className="bg-sky-900 text-white py-1 px-2 rounded-md ri-search-line"></i>
        </div>
        <Tabs className='bg-white'  align='start' variant='enclosed'>
        <TabList mb='1em'>
            <Tab className='font-bold'>Workspace</Tab>
            <Tab className='font-bold'>Upload and Export</Tab>
        </TabList>
        <TabPanels>
            <TabPanel>
                <div>
                <div className='md:grid md:grid-cols-5 gap-x-8 gap-y-8 sm:grid sm:grid-cols-2 mb-6'>
                <div className='border border-slate-300 bg-slate-500 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Type</p>
                    <p className='text-sm mb-4'>Single Node CPU Instance
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800  border-b-4 text-white  bg-slate-700 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>OS</p>
                    <p className='text-sm mb-4'>Amazon Linux 2
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800 border-b-4 text-white  bg-sky-950 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>vCPUs</p>
                    <p className='text-sm mb-4'>0 - 4
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800  border-b-4 text-white  bg-slate-500 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Memory</p>
                    <p className='text-sm mb-4'>0GB - 32GB
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800  border-b-4 text-white  bg-sky-950 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>GPUs</p>
                    <p className='text-sm mb-4'>0
                    </p>
                </div>
            </div>
                </div>
                <Email/>
            </TabPanel>
            <TabPanel>
            <div className='md:grid md:grid-cols-5 gap-x-8 gap-y-8 sm:grid sm:grid-cols-2'>
            <div className='border border-slate-300 bg-slate-500 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                <p className='mb-4 text-xl'>Type</p>
                    <p className='text-sm mb-4'>Single Node CPU Instance
                    </p>
                </div>
                <div className='border border-slate-300 bg-slate-700 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>OS</p>
                    <p className='text-sm mb-4'>Amazon Linux 2
                    </p>
                </div>
                <div className='border border-slate-300 bg-sky-950 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>vCPUs</p>
                    <p className='text-sm mb-4'>0 - 4
                    </p>
                </div>
                <div className='border border-slate-300 bg-slate-500 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Memory</p>
                    <p className='text-sm mb-4'>0GB - 32GB
                    </p>
                </div>
                <div className='border border-slate-300 bg-slate-700 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>GPUs</p>
                    <p className='text-sm mb-4'>0
                    </p>
                </div>
            </div>
            <Chatbot />
            </TabPanel>
        </TabPanels>
        </Tabs>
        </div>
  )
}

export default Workspace;